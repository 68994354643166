<template>
    <div>
        <v-container class="my-2 text-left">
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="350px"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-model="date"
                        readonly
                        v-on="on"
                        color="indigo"
                        dark
                        class="mr-5 mb-4"
                    >
                        {{ date }}
                    </v-btn>
                </template>
                <v-date-picker
                    v-model="date"
                    scrollable
                    type="month"
                    @change="dateChange(date)"
                >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date)"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-dialog>
        </v-container>
        <div class="d-flex flex-row align-baseline">
            <v-text-field
                @input="setFilterTaskSearchAdmin"
                :value="getTaskSearchAdmin"
                label="Filtruj"
                single-line
                hide-details
                class="mb-3 mr-5"
            ></v-text-field>
            <v-switch
                v-model="getSwitchEnd"
                @change="updateFilterSwitchEnd()"
                label="Zakończone"
                class="mr-4"
            ></v-switch>
            <v-switch
                v-model="getSwitchReject"
                @change="updateFilterSwitchReject()"
                label="Anulowane"
            ></v-switch>
        </div>
        <v-data-table
            :loading="!isDownloaded"
            :search="getTaskSearchAdmin"
            :headers="headers"
            :items="filteredItems"
            class="elevation-2"
            :sort-desc="false"
            :sort-by="['date']"
            :disable-pagination="true"
            :hide-default-footer="true"
            item-key="id"
        >
            <template v-slot:item.clientId="{ item }">
                {{ item.clientId ? item.clientId : "" }}
            </template>

            <template v-slot:item.copywriterId="{ item }">
                <div :class="{ opacity: item.copywriterIsbanned }">
                    {{ item.copywriterId ? item.copywriterId : "" }}
                </div>
            </template>

            <template v-slot:item.korektor="{ item }">
                <div :class="{ opacity: item.korektorIsBanned }">
                    {{ item.korektor ? item.korektor : "" }}
                </div>
            </template>

            <template v-slot:item.date="{ item }">
                {{ item.date ? item.date.split("T")[0] : "" }}
            </template>

            <template v-slot:item.dateCp="{ item }">
                {{ item.dateCp ? item.dateCp.split("T")[0] : "" }}
                {{ correctDate(item.dateCp) }}
            </template>

            <template v-slot:item.copyRate="{ item }">
                {{ item.copyRate ? item.copyRate.toFixed(2) : "" }}
            </template>

            <template v-slot:item.fullRate="{ item }">
                {{ item.fullRate ? item.fullRate.toFixed(2) : "" }}
            </template>

            <template v-slot:item.status="{ item }">
                <StatusChip :status="item.status" />
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                    <v-btn
                        color="green"
                        class="mt-1 mb-1 white--text"
                        @click.stop="changeRoute(item._id)"
                    >
                        Zobacz
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import io from "socket.io-client";
import StatusChip from "@/components/molecules/StatusChip.vue";

var connectionOptions = {
    "force new connection": true,
    reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket"],
};

export default {
    components: {
        StatusChip,
    },
    data() {
        return {
            tasks: [],
            isDownloaded: false,
            clients: [],
            socket: io(this.url, connectionOptions),
            text: "Zadanie zostało usunięte pomyślnie",
            timeout: 2000,
            doneDisclaimer: true,
            inprogressDisclaimer: true,
            rejectedDisclaimer: true,
            headers: [
                {
                    text: "Zadanie",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                { text: "Klient", value: "clientId", sortable: true },
                {
                    text: "Deadline copywriter",
                    value: "dateCp",
                    sortable: true,
                },
                { text: "Deadline", value: "date", sortable: true },
                { text: "Copywriter", value: "copywriterId", sortable: true },
                { text: "Korektor", value: "korektor", sortable: true },
                {
                    text: "Stawka copywritera",
                    value: "copyRate",
                    sortable: true,
                },
                { text: "Stawka", value: "fullRate", sortable: true },
                { text: "Znaki", value: "characters", sortable: true },
                { text: "Status", value: "status", sortable: true },
                { text: "Akcje", value: "actions", sortable: false },
            ],
            date: this.$route.params.month,
            modal: false,
        };
    },

    computed: {
        role() {
            return this.$store.getters.getUserRole;
        },
        getTaskSearchAdmin() {
            return this.$store.getters.getTaskSearchAdmin;
        },
        getSwitchAccepted: {
            set(newValue) {
                console.log(newValue);
            },
            get() {
                return this.$store.getters.getSwitchAccepted;
            },
        },
        getSwitchEnd: {
            set(newValue) {
                console.log(newValue);
            },
            get() {
                return this.$store.getters.getSwitchEnd;
            },
        },
        getSwitchReject: {
            set(newValue) {
                console.log(newValue);
            },
            get() {
                return this.$store.getters.getSwitchReject;
            },
        },
        filteredItems() {
            return this.tasks.filter((i) => {
                switch (i.status) {
                    case "Zakończone":
                        if (this.getSwitchEnd == false) {
                            return i;
                        }
                        break;
                    case "Anulowane":
                        if (this.getSwitchReject == false) {
                            return i;
                        }
                        break;
                    default:
                        return i;
                }
            });
        },
    },
    methods: {
        setFilterTaskSearchAdmin(text) {
            this.$store.commit("updateFilterTaskSearchAdmin", text);
        },
        updateFilterSwitchReject() {
            this.$store.commit(
                "updateFilterSwitchReject",
                !this.getSwitchReject
            );
        },
        updateFilterSwitchEnd() {
            this.$store.commit("updateFilterSwitchEnd", !this.getSwitchEnd);
        },
        correctDate(date) {
            const newDate = new Date(date);
            return `${newDate.getHours()}:${
                newDate.getMinutes() < 9
                    ? "0" + newDate.getMinutes()
                    : newDate.getMinutes()
            }`;
        },
        getAllTasks() {
            this.$axios
                .get(`${this.url}api/tasks/from/${this.date}`)
                .then((resp) => {
                    const filtredData = resp.data.map((el) => {
                        return {
                            characters: el.characters,
                            checked: el.checked,
                            clientId: el.clientId ? el.clientId.name : "",
                            copyRate:
                                el.characters !== 0 ? el.copyRate : el.copyRate,
                            copywriterId: el.copywriterId
                                ? el.copywriterId.name
                                : "",
                            copywriterIsbanned: el.copywriterId
                                ? el.copywriterId.isBanned
                                : "",
                            date: el.date,
                            dateCp: el.dateCp,
                            korektor: el.korektorId ? el.korektorId.name : "",
                            korektorIsBanned: el.korektorId
                                ? el.korektorId.isBanned
                                : "",
                            description: el.description,
                            fullRate:
                                el.characters !== 0 ? el.fullRate : el.fullRate,
                            name: el.name,
                            orderType: el.orderType,
                            paid: el.paid,
                            status: el.status,
                            updateTime: el.updateTime,
                            _v: el._v,
                            _id: el._id,
                        };
                    });
                    this.tasks = filtredData;
                    this.isDownloaded = true;
                });
        },
        changeRoute(id) {
            this.$router.push(`task/${id}`);
        },
        dateChange(date) {
            this.$router.push(`../../${this.role}/${date}`);
            this.getAllTasks();
        },
    },
    created() {
        this.getAllTasks();

        this.socket.on("newTaskAdded", () => {
            this.getAllTasks();
        });

        this.socket.on("taskDeleted", () => {
            this.getAllTasks();
        });
        this.socket.on("editedTask", () => {
            this.getAllTasks();
        });
    },
};
</script>

<style scoped lang="scss">
.opacity {
    opacity: 0.6 !important;
}
</style>
